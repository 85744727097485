.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

  /* top-left border-radius */
  table thead:first-child th:first-child {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    padding-left: 2rem;
    min-width: 300px;
  }
  
  /* top-right border-radius */
  table thead:first-child th:last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  
  table tr td:first-child {
    padding-left: 2rem;
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
  }
  
  table tr td:last-child {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
  
  table tr td {
    font-weight: 500;
    padding: 1rem 0;
  }
  
  th {
    padding: 1rem 0;
    font-size: large;
    color: #052c57;
    text-align: start;
    min-width: 150px;
  }

  table thead:first-child th:first-child {
    padding-left: 1 rem;
  }